import React, { useState, useEffect } from "react";
import { SkaterStats } from "../../components/SkaterStats/SkaterStats";
import { GoalieStats } from "../../components/GoalieStats/GoalieStats";
import { StatsLegend } from "../../components/StatsLegend/StatsLegend";
import axios from "axios";

import "./statspage.css";

export const StatsPage = () => {
  const [updatedWeekNum, setUpdatedWeekNum] = useState(0);
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchLastUpdateNumber = async () => {
      try {
        const response = await axios.get(
          "https://cmhl.vercel.app/api/week_number"
        );
        console.log(response.data);
        setUpdatedWeekNum(response.data[0][3]);
      } catch (e) {
        console.error("Failed to fetch updated week number:", e);
        setError("ERROR: Failed to fetch updated week number");
      }
    };
    fetchLastUpdateNumber();
  }, []);

  if (error) return <h2>{error}</h2>;

  return (
    <div className="statspage">
      <div className="statspage--body">
        <div className="statspage--header">
          <h1>Stats</h1>
          <h3>Updated as of Week {updatedWeekNum}</h3>
          <h2>Skaters</h2>
        </div>
        <SkaterStats />
        <div className="statspage--header">
          <h2>Goalies</h2>
        </div>
        <GoalieStats />
        <StatsLegend />
      </div>
    </div>
  );
};
